exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-en-js": () => import("./../../../src/pages/about.en.js" /* webpackChunkName: "component---src-pages-about-en-js" */),
  "component---src-pages-about-zh-js": () => import("./../../../src/pages/about.zh.js" /* webpackChunkName: "component---src-pages-about-zh-js" */),
  "component---src-pages-cases-en-js": () => import("./../../../src/pages/cases.en.js" /* webpackChunkName: "component---src-pages-cases-en-js" */),
  "component---src-pages-cases-zh-js": () => import("./../../../src/pages/cases.zh.js" /* webpackChunkName: "component---src-pages-cases-zh-js" */),
  "component---src-pages-contact-en-js": () => import("./../../../src/pages/contact.en.js" /* webpackChunkName: "component---src-pages-contact-en-js" */),
  "component---src-pages-contact-zh-js": () => import("./../../../src/pages/contact.zh.js" /* webpackChunkName: "component---src-pages-contact-zh-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-zh-js": () => import("./../../../src/pages/index.zh.js" /* webpackChunkName: "component---src-pages-index-zh-js" */),
  "component---src-pages-services-en-js": () => import("./../../../src/pages/services.en.js" /* webpackChunkName: "component---src-pages-services-en-js" */),
  "component---src-pages-services-zh-js": () => import("./../../../src/pages/services.zh.js" /* webpackChunkName: "component---src-pages-services-zh-js" */),
  "component---src-templates-blog-post-archive-js": () => import("./../../../src/templates/blog-post-archive.js" /* webpackChunkName: "component---src-templates-blog-post-archive-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

